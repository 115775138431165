<template>
    <!-- ability radar  个人能力图谱 雷达图 -->
    <div class="ability-radar">
        <div class="chart-box" v-show="radarFlag">
            <div id="radar"></div>
        </div>
        <!-- 无内容-->
        <Empty :show="!radarFlag"></Empty>
    </div>

</template>

<script>
    export default {
        props: {
            suitId: {
                type: Number
            },
        },
        data() {
            return {
                radarFlag: true,
                radarData: [],
                radarDataValue: [],
            }
        },
        watch: {
            suitId: {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.getAbilityRadar();
                },
                immediate: true,
            }
        },
        methods: {
            // 获取综合个人能力数据数据
            async getAbilityRadar() {
                let params = {
                    suitId: this.suitId
                }
                let res = await this.$Api.DST.userSecendAbility(params)
                let resData = res.data;
                this.radarData = [];
                this.radarDataValue = [];
                if (Array.prototype.isPrototypeOf(resData) && resData.length > 0) {
                    this.radarFlag = true;
                    const maxData = Math.max.apply(Math, resData.map(function (item) {
                        return item.value << 0
                    }));
                    resData.map(item => {
                        let data = {
                            name: item.name,
                            max: maxData + 5,
                        }
                        this.radarDataValue.push(item.value);
                        this.radarData.push(data);
                    })
                    // myChartRadar.setOption(myChartRadarOption(myChartRadardata, myChartRadardatavalue));
                    this.drawRadar();
                } else {
                    this.radarFlag = false;
                }
            },
            drawRadar() {
                let myChartRadar = this.$echarts.init(
                    document.getElementById("radar")
                );
                window.addEventListener('resize', () => {
                    myChartRadar.resize()
                })
                myChartRadar.setOption({
                    tooltip: {
                        trigger: 'item',
                    },
                    radar: [{
                        indicator: this.radarData,
                        // indicator: 
                        // [
                        //     { name: "学情分析能力", max: 200 },
                        //     { name: "教学过程设计能力", max: 200 },
                        //     { name: "教学策略\r\n选择能力", max: 200 },
                        //     { name: "课程资源开发能力", max: 200 },
                        //     { name: "课程资源利用能力", max: 200 },
                        //     { name: "口语表达能力", max: 200 },
                        //     { name: "课堂调控能力", max: 200 },
                        //     { name: "学习研究能力", max: 200 },
                        //     { name: "创新能力", max: 200 }
                        // ],
                        radius: '60%',
                        center: ['50%', '50%'],
                        // startAngle: 90,
                        splitNumber: 4,
                        shape: "circle",
                        name: {
                            show: true,
                            color: "#123456",
                            fontSize: "15",

                            // formatter: "{value}",
                            // formatter: function (text) {
                            //     var strlength = text.length;
                            //     if (strlength % 4 == 2) {
                            //         text = text.replace(/\S{6}/g, function (match) {
                            //             // console.log(match);
                            //             return match + '\n'
                            //         })
                            //     } else {
                            //         text = text.replace(/\S{6}/g, function (match) {
                            //             // console.log(match);
                            //             return match + '\n'
                            //         })
                            //         strlength = text.length;
                            //         text = text.substring(0, strlength);
                            //     }
                            //     return text
                            // },

                        },
                        splitArea: {
                            areaStyle: {
                                color: [
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",

                                ],
                                shadowColor: "rgba(0, 0, 0, 0)",
                                // shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#E1F1FE"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#E5F3FE"
                            }
                        }
                    }],
                    series: [{
                        name: "个人能力图谱",
                        type: "radar",
                        emphasis: {},
                        data: [{
                            value: this.radarDataValue,
                            symbol: "none", //设置拐点格式样式
                            lineStyle: {
                                color: "transparent"
                            },
                            areaStyle: {
                                // color: "#126CC0"
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#15A1E2' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: '#126CC0' // 100% 处的颜色
                                    }],
                                }

                            }
                        }]
                    }]
                })

            }
        },
        mounted() {
            // this.getAbilityRadar();
        },
    }
</script>

<style lang="less" scoped>
    .ability-radar {
        #radar {
            width: 700px;
            height: 650px;
            margin: -50px auto;
        }

        // @media screen and (min-width: 1250px) {
        //   .myChart-canvas-box {
        //     width: 395px;
        //     height: 348px;
        //   }
        // }

    }
</style>