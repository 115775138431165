<template>
  <!-- 个人能力报告 -->
  <div class="ability-personal">
    <div class="ability-personal-wrapper manage-wrapper">
      <div class="ability-personal-conatiner">
        <div class="title-part flex-align-between">
          <h3 class="title">{{info.name}}
            {{ `双优云桥${currentRole == 'student' ? '学生': currentRole == 'teacher'?'教师':''}综合能力报告` }}</h3>
          <div class="toolbar">
            <el-select v-model="selectedTerm" placeholder="请选择" size="mini">
              <el-option v-for="item in termList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-button type="text" class="export-btn">导出报告</el-button> -->
          </div>
        </div>
        <div class="ability-personal-main">
          <!-- flex-between -->
          <div class="charts ">
            <div class="composite-charts">
              <h3 class="charts-title">综合个人能力</h3>
              <Sunburst :suitId="Number($route.query.suitId)"></Sunburst>
              <!-- <div v-show="Sunburstflag" id="myChart-Sunburst"></div>
              <Empty :show="!Sunburstflag"></Empty> -->
            </div>
            <div class="personal-charts">
              <h3 class="charts-title">个人能力图谱</h3>
              <Radar :suitId="Number($route.query.suitId)"></Radar>
              <!-- <div v-show="Radarflag" id="myChart-Radar"></div>
              <Empty :show="!Radarflag"></Empty> -->
            </div>
          </div>
          <div class="ability-table" v-for="(ability, index) in abilityList" :key="index">
            <div class="ability-table-title-part flex-align-between">
              <div class="form-title">{{ ability.title }}（获得{{ ability.score }}分）</div>
              <button class="promote-btn csp" @click="isShowDialog(index)">
                <i class="rocket"></i>
                提升能力
              </button>
            </div>
            <div class="alibity-table-main">
              <el-table :data="ability.children" border style="width: 100%">
                <el-table-column prop="secendAbilityName" align="center" label="二级能力">
                </el-table-column>
                <el-table-column prop="workName" align="center" label="作业名称">
                </el-table-column>
                <el-table-column prop="value" align="center" label="能力分">
                </el-table-column>
              </el-table>
              <!-- <div class="btn-box">
            <button class="btn-show">
              展开 <i class="el-icon-arrow-down" style="margin-left:22px"></i>
            </button>
          </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="450px" top="30vh" :center="true">
      <div class="content">
        您目前在“{{ title }}”上获得
        <span style="color:#508EF9">{{ score }}能力分</span> ，
        快去提升吧！
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="promote">去提升</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import Sunburst from "./component/pa-sunburst.vue";
  import Radar from "./component/alibity-radar.vue";
  import {
    myChartRadarOption,
    myChartSunburstOption,
  } from "@/common/options";
  export default {
    components: {
      Sunburst,
      Radar,
    },
    data() {
      return {
        termList: [{
          value: 0,
          label: '上学期'
        }],
        selectedTerm: 0,
        Sunburstflag: true,
        Radarflag: true,
        dialogVisible: false,
        sexMap: {
          0: require("@/assets/images/my/men.png"),
          1: require("@/assets/images/my/women.png"),
          2: require("@/assets/images/my/men.png")
        },
        topTitle: "双优云桥教师综合能力报告",
        info: {},
        abilityList: [],
        title: "",
        score: "",
        abilityId: null,
        // currentRole: sessionStorage.getItem(['role'])
      };
    },
     computed: {
      // ...mapState({
      //   currentRole: state => state.role.role,
      // })
      currentRole(){
        return this.$store.state.user.role
      }
    },
    methods: {
      async getUserAbilityScurce() {
        let pamars = {
          suitId: this.$route.query.suitId,
          userId: this.info.id
        }
        let res = await this.$Api.DST.getUserAbilityScurce(pamars);
        this.abilityList = [];
        if (Object.prototype.isPrototypeOf(res.data)) {
          Object.keys(res.data).forEach(key => {
            let sum = 0;
            if (Array.prototype.isPrototypeOf(res.data[key]) && res.data[key].length > 0) {
              res.data[key].forEach((val, index) => {
                sum += val.value;
              })
            }
            let value = {
              title: key,
              abilityId: res.data[key][0].firstAbility,
              score: sum,
              children: res.data[key]
            }
            this.abilityList.push(value)
          })
        }
      },
      // 弹框提示
      isShowDialog(index) {
        this.dialogVisible = true;
        this.title = this.abilityList[index].title;
        this.score = this.abilityList[index].score;
        this.abilityId = this.abilityList[index].abilityId;
      },
      promote() {
        this.dialogVisible = false;
        this.$router.push({
          name: "ability_report_improve",
          query: {
            title: this.title,
            abilityId: this.abilityId,
            suitId: this.$route.query.suitId,
            breadNumber: 5,
          },
        });
      },
      async drawLine() {

        // 综合个人能力
        // let myChartSunburst = this.$echarts.init(
        //   document.getElementById("myChart-Sunburst")
        // );
        // console.log(myChartSunburst);
        // let pamars = {
        //   suitId: this.$route.query.suitId
        // }
        // let resDatas = await this.$Api.DST.userFristAbility(pamars);
        // console.log(Array.prototype.isPrototypeOf(resDatas) && resDatas.length > 0);
        // if (Array.prototype.isPrototypeOf(resDatas) && resDatas.length > 0) {
        //   this.Sunburstflag = true;
        //   resDatas.map(item => {
        //     item.childList.map((resitem) => {
        //       resitem.name = `${resitem.name}${resitem.value}`
        //     })
        //     item.children = item.childList;
        //   })
        //   console.log(resDatas);
        //   myChartSunburst.setOption(myChartSunburstOption(resDatas));
        // } else {
        //   this.Sunburstflag = false;
        // }

        // 个人能力图谱
        // let myChartRadar = this.$echarts.init(
        //   document.getElementById("myChart-Radar")
        // );


        // let resData = await this.$Api.DST.userSecendAbility(pamars);
        // let myChartRadardata = [];
        // let myChartRadardatavalue = [];
        // if (Array.prototype.isPrototypeOf(resData) && resData.length > 0) {
        //   this.Radarflag = true;
        //   const maxData = Math.max.apply(Math, resData.map(function (item) {
        //     return item.value << 0
        //   }));
        //   resData.map(item => {
        //     let data = {
        //       name: item.name,
        //       max: maxData + 5,
        //     }
        //     myChartRadardatavalue.push(item.value);
        //     myChartRadardata.push(data);
        //   })
        //   console.log(myChartRadardata);
        //   myChartRadar.setOption(myChartRadarOption(myChartRadardata, myChartRadardatavalue));
        // } else {
        //   this.Radarflag = false;
        // }

        // 图表浏览器自适应
        setTimeout(function () {
          window.onresize = function () {
            // 综合个人能力
            // myChartSunburst.resize();
            // 个人能力图谱
            // myChartRadar.resize();
          };
        }, 200);
      }
    },
    mounted() {
      // this.info = JSON.parse(sessionStorage.getItem('userinfo'));
      this.info = this.$store.state.user.userInfo
      this.getUserAbilityScurce()
      this.drawLine();
    },
    activated() {
      // this.info = JSON.parse(sessionStorage.getItem('userinfo'));
      this.info = this.$store.state.user.userInfo
      this.getUserAbilityScurce()
      this.drawLine();
    }
  };
</script>

<style lang="less" scoped>
  .ability-personal {
    .ability-personal-conatiner {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 2px 10px 0px #F5F8FA;
    }

    .title-part {
      border-bottom: 1px solid #f2f2f2;
      padding: 0 40px 0 75px;
      line-height: 50px;

      .title {
        color: #508EF9;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        &::before {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 50%;
          height: 5px;
          border-radius: 2px;
          background-color: #508EF9;
          transform: translateX(-50%);
        }
      }

      /deep/ .el-select .el-input__inner {
        border-radius: 25px;
        width: 120px;
        height: 25px;
        line-height: 25px;
      }

      .export-btn {
        margin-left: 28px;
        color: #666;
      }
    }

    .ability-personal-main {
      padding: 50px 36px;

      .charts {
        padding: 0 40px;

        .charts-title {
          font-size: 15px;
          font-weight: 600;

          &::before {
            display: inline-block;
            content: ' ';
            margin-right: 8px;
            width: 3px;
            height: 15px;
            border-radius: 3px;
            background-color: #508EF9;
            vertical-align: -2px;
          }
        }

        #myChart-Sunburst,
        #myChart-Radar {
          width: 350px;
          height: 350px;
        }

        #myChart-Sunburst {
          margin-left: 0px;
        }

        #myChart-Radar {
          margin-right: 0px;
        }
      }

      .ability-table {
        margin-top: 31px;

        .ability-table-title-part {
          border-bottom: 1px solid #F2F2F2;
          margin-bottom: 20px;
          padding: 0 19px 13px 40px;
        }

        .form-title {
          font-size: 15px;
          font-weight: 600;

          &::before {
            display: inline-block;
            content: ' ';
            margin-right: 8px;
            width: 3px;
            height: 15px;
            border-radius: 3px;
            background-color: #508EF9;
            vertical-align: -2px;
          }
        }

        .promote-btn {
          padding: 0 15px;
          line-height: 28px;
          border-radius: 4px;
          color: #fff;
          font-size: 13px;
          background-color: #508EF9;
          box-shadow: 0px 4px 11px 0px rgba(22, 115, 255, 0.28);

          &:hover {
            background-color: rgba(80, 142, 249, 0.8);
          }

          .rocket {
            display: inline-block;
            margin-right: 7px;
            width: 15px;
            height: 15px;
            background-image: url("../../assets/images/ability/rocket.png");
            background-size: cover;
            vertical-align: -3px;
          }
        }

        .alibity-table-main {
          padding: 0 20px;

          /deep/.el-table {
            border-radius: 5px;
            overflow: hidden;

            th {
              background: #FAFBFF;
              color: #333;
            }
          }
        }
      }

    }

    /deep/ .el-dialog {
      border-radius: 5px;
      overflow: hidden;

      .el-dialog__header {
        background-image: url("../../assets/images/teach/create-title.png");
        background-size: cover;
        line-height: 40px;
        padding: 0;

        .el-dialog__title {
          font-size: 17px;
          color: #fff;
        }

        .el-dialog__headerbtn {
          top: 10px;
        }

        .el-dialog__close {
          color: #fff
        }
      }

      .el-dialog__body {
        padding: 40px 40px 65px;
      }

      .el-button {
        padding: 0 30px;
        line-height: 30px;
        font-size: 13px;
        background-color: #508EF9;
      }
    }

    @media screen and (min-width:1250px) {
      .ability-personal-main .charts {
        #myChart-Sunburst {
          margin-left: 100px;
        }

        #myChart-Radar {
          margin-right: 100px;
        }
      }
    }
  }
</style>